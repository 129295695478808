import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css'; 



const PlayerSearch = ({ onSelectPlayer }) => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
   
    


    const handlePlayerSelection = (player) => {
        onSelectPlayer(player); // Existing player selection logic
        setQuery(''); // Clear the search query
        setResults([]); // Clear the search results


        // Scroll to top on mobile devices upon player selection
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }

        // Check for webkit message handler and post message
        if (window.webkit && window.webkit.messageHandlers.selectedPlayer) {
        window.webkit.messageHandlers.selectedPlayer.postMessage(player);
    }


    };

    useEffect(() => {
        if (query.length >= 4) {
            const fetchPlayers = async () => {
                try {
                    const response = await axios.get(`https://futchem.com/api/search-players?query=${query}`);
                    setResults(response.data);
                } catch (error) {
                    console.error("Error fetching players", error);
                }
            };

            const delayDebounce = setTimeout(() => fetchPlayers(), 500);

            return () => clearTimeout(delayDebounce);
        } else {
            setResults([]);
        }
    }, [query]);

    return (
        <div>
            <input 
                type="text" 
                value={query} 
                onChange={(e) => setQuery(e.target.value)} 
                placeholder="Search Players - Start typing Player Surname"
            />
            <ul>
                {results.map(player => (
                    <li key={player.id} onClick={() => handlePlayerSelection(player)} className="player-item">
                        {player.nationality_img ? 
                            <img src={player.nationality_img} alt="Nationality" className="player-img nationality-img" />
                            : <div className="placeholder nationality-img"></div>
                        }
                        {player.league_img ? 
                            <img src={player.league_img} alt="League" className="player-img league-img" />
                            : <div className="placeholder league-img"></div>
                        }
                        {player.club_img ? 
                            <img src={player.club_img} alt="Club" className="player-img club-img" />
                            : <div className="placeholder club-img"></div>
                        }
                        <span className="player-name">{player.name}</span>
                        <div className="player-details">
                            <span>Rating: {player.rating}</span>
                            <span>Position: {player.position}</span>
                            <span>Alternative Positions: {Array.isArray(player.secondary_positions) ? player.secondary_positions.join(', ') : player.secondary_positions}</span>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default PlayerSearch;
