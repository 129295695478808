import React, { useState, useEffect, useRef } from 'react';
import PlayerSearch from './PlayerSearch';
import SelectedPlayers from './SelectedPlayers';
import axios from 'axios';

// Set the base URL for Axios dynamically based on environment
axios.defaults.baseURL = process.env.NODE_ENV === 'production'
  ? 'https://futchem.com/api'
  : 'http://localhost:3001';

function App() {
    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [optimizationResult, setOptimizationResult] = useState(null);
    const [formations, setFormations] = useState({});
    const audioRef = useRef(null);

    // Show the notice on app load
    useEffect(() => {
        alert("**NOTICE**\nChemistry Algorithm is currently being prepared for EAFC25, and it is not functioning correctly for EAFC24.");
    }, []); // Empty dependency array ensures this runs only on component mount

    useEffect(() => {
        console.log('Fetching formations from', axios.defaults.baseURL + '/get-formations');
        axios.get('/get-formations')
            .then(response => {
                console.log('Formations fetched successfully:', response.data);
                setFormations(response.data);
            })
            .catch(error => {
                console.error('Error fetching formations:', error);
                if (error.response) {
                    console.error('Response data:', error.response.data);
                    console.error('Response status:', error.response.status);
                    console.error('Response headers:', error.response.headers);
                } else if (error.request) {
                    console.error('Request made but no response received:', error.request);
                } else {
                    console.error('Error setting up the request:', error.message);
                }
            });
    }, []);

    const handleSelectPlayer = (player) => {
        console.log('Selected player:', player);
        setSelectedPlayers(prevPlayers => [...prevPlayers, player]);
        if (audioRef.current) {
            audioRef.current.play();
        }
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.playerSelected) {
            window.webkit.messageHandlers.playerSelected.postMessage(JSON.stringify(player));
            console.log("Sent player data to Swift", player); 
        }
    };

    const handleOptimizeChemistry = () => {
        console.log('Optimizing chemistry with players:', selectedPlayers);
        axios.post('/calculate-chemistry', { players: selectedPlayers })
            .then(response => {
                console.log('Optimization result:', response.data);
                setOptimizationResult(response.data);
                alert(JSON.stringify(response.data));
            })
            .catch(error => {
                console.error("Error in optimization:", error);
            });
    };

    const handleRemovePlayer = (playerId) => {
        const updatedPlayers = selectedPlayers.filter(player => player.id !== playerId);
        console.log('Removed player, updated players:', updatedPlayers);
        setSelectedPlayers(updatedPlayers);
    };

    const sortedSquad = optimizationResult && formations[optimizationResult.best_formation_name] ? 
        optimizationResult.best_formation_squad.sort((a, b) => {
            const formationPositions = formations[optimizationResult.best_formation_name];
            return formationPositions.indexOf(a.position) - formationPositions.indexOf(b.position);
        }) : [];

    return (
        <div>
            <div className="nav-bar">
                <a href="/"></a>
                <span><a href="/">futChem</a></span>
                <a href="/"></a>
            </div>
            <div className="App">
                <div className="box1">
                    <h2>Select Players</h2>
                    <PlayerSearch onSelectPlayer={handleSelectPlayer} />
                    <audio ref={audioRef} src="/confirm.wav" preload="auto" />
                </div>
                <div className="box2">
                    <h2>Squad</h2>
                    {selectedPlayers.length > 0 && (
                        <div className="count-badge">{selectedPlayers.length}</div>
                    )}
                    <SelectedPlayers 
                        players={selectedPlayers} 
                        onOptimizeChem={setOptimizationResult} 
                        onRemovePlayer={handleRemovePlayer} 
                    />
                </div>
                <div className="box3">
                    {optimizationResult && (
                        <div>
                            <center><h3>Best Formation: {optimizationResult.best_formation_name}</h3></center>
                            <center><p>Chemistry Score: {optimizationResult.best_chemistry}</p></center>
                            <ul>
                                {sortedSquad.map((item, index) => (
                                    <li key={index}>
                                        <span>{item.position}: {item.player.name} - Chemistry Points: {item.chemistry_points}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
            <footer className="site-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <h1 style={{margin: "0 40px"}}>About futChem</h1>
                            <p className="text-justify" style={{margin: "0 40px"}}>
                                futChem.com was created by UT players for UT players. 
                                We wanted to be able to create a team of our favourite players
                                and then see what the best possible chemistry could be attained.
                                With added complications such as secondary positions, radioactive cards, 
                                new Icon links and Hero links, we thought the time was right to 
                                create an algorithm which worked out the best formation for us.
                            </p>   
                            <br></br>
                            <h1 style={{margin: "0 40px"}}>Instructions</h1>
                            <p className="text-justify" style={{margin: "0 40px"}}>
                                1. Enter the players surname into the first box<br></br>
                                2. Once 11 players are added, press the 'optimise chem' button<br></br>
                                3. Your team will be shown with the best possible chemistry out of the 32 formations<br></br>
                                4. Tick the check box for those of your players who are radioactive
                            </p>
                        </div>
                        <div className="col-xs-6 col-md-3">
                            <br></br>
                            <h1 style={{margin: "0 40px"}}>Quick Links</h1>
                            <ul className="footer-links">
                                <li style={{margin: "0 40px"}}><a href="#">futChem Tool</a></li>
                                <li style={{margin: "0 40px"}}><a href="https://futchem.com/contact-us">Contact Us</a></li>
                                <li style={{margin: "0 40px"}}><a href="https://futchem.com/privacy-policy">Privacy Policy</a></li>
                                <br></br>
                            </ul>
                        </div>
                    </div>
                    <hr/>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-sm-6 col-xs-12">
                            <p className="copyright-text">
                                Copyright &copy; 2023 All Rights Reserved by 
                                <a href="https://monexapps.com"> monexapps</a>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default App;
