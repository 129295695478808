import React, { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

const extractDataFromImageUrl = (imageUrl) => {
    if (!imageUrl) return '';
    const parts = imageUrl.split('/');
    return String(parts[parts.length - 1].split('.')[0]);
};

export const SelectedPlayers = ({ players, onRemovePlayer, onOptimizeChem }) => {
    const [radioactiveStatus, setRadioactiveStatus] = useState({});
    const [versusiceStatus, setversusiceStatus] = useState({});
    const [versusfireStatus, setversusfireStatus] = useState({});



    const handleRadioactiveToggle = (playerId) => {
        setRadioactiveStatus(prevStatus => ({
            ...prevStatus,
            [playerId]: !prevStatus[playerId]
        }));
    };

    const handleVersusIceToggle = (playerId) => {
        setversusiceStatus(prevStatus => ({
            ...prevStatus,
            [playerId]: !prevStatus[playerId]
        }));
    };

    const handleVersusFireToggle = (playerId) => {
        setversusfireStatus(prevStatus => ({
            ...prevStatus,
            [playerId]: !prevStatus[playerId]
        }));
    };

    const handleOptimizeChem = () => {
        const formattedPlayers = players.map(player => ({
            ...player,
            radioactive: !!radioactiveStatus[player.id],
            versusice: !!versusiceStatus[player.id],
            versusfire: !!versusfireStatus[player.id],
            position: [player.position],
            secondary_positions: Array.isArray(player.secondary_positions) ? player.secondary_positions : (player.secondary_positions ? [player.secondary_positions] : []),
            club: extractDataFromImageUrl(player.club_img),
            nation: extractDataFromImageUrl(player.nationality_img),
            league: extractDataFromImageUrl(player.league_img),

        }));

       // Debug: Log the formatted players sent to the API
       console.log("Optimising Chemistry with players:", formattedPlayers);


        axios.post('https://futchem.com/api/calculate-chemistry', { players: formattedPlayers })
            .then(response => {
                // Serialize the response data to a JSON string and send it to the Swift side
           //     const dataForNativeApp = JSON.stringify(response.data);
           //     window.webkit.messageHandlers.chemistryHandler.postMessage(dataForNativeApp);



                onOptimizeChem(response.data);
                // Check if the device is mobile
                const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
                if (isMobile) {
                    // Scroll to the top of .box3
                    const box3 = document.querySelector('.box3');
                    if (box3) {
                        box3.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }
                } else {
                    // For non-mobile devices, scroll to the top of the page
                    window.scrollTo(0, 0);
                }
            })
            .catch(error => {
                console.error("Error optimizing chemistry:", error);
            });
    };

const handleRemovePlayerClick = (playerId) => {
        onRemovePlayer(playerId);
    };

    return (
        <div>
            <ul>
                {players.map(player => (
                    <li key={player.id} className="player-item">
                        <div className="close-btn" onClick={() => handleRemovePlayerClick(player.id)}>x</div>
                        {player.nationality_img && <img src={player.nationality_img} alt="Nationality" />}
                        {player.league_img && <img src={player.league_img} alt="League" />}
                        {player.club_img && <img src={player.club_img} alt="Club" />}
                        <span className="player-name">{player.name}</span>
                        <div className="player-details">
                            <span>Rating: {player.rating}</span>
                            <span>Position: {player.position}</span>
                            <span>Alternative Positions: {Array.isArray(player.secondary_positions) ? player.secondary_positions.join(', ') : player.secondary_positions}</span>
                        </div>
                        <div className="player-toggles">
                        <label className="toggle-option">
                            Radioactive
                            <input
                                type="checkbox"
                                checked={!!radioactiveStatus[player.id]}
                                onChange={() => handleRadioactiveToggle(player.id)}
                            />
                        </label>
                        <label className="toggle-option">
                            Versus Ice
                            <input
                                type="checkbox"
                                checked={!!versusiceStatus[player.id]}
                                onChange={() => handleVersusIceToggle(player.id)}
                            />
                        </label>
                        <label className="toggle-option">
                            Versus Fire
                            <input
                                type="checkbox"
                                checked={!!versusfireStatus[player.id]}
                                onChange={() => handleVersusFireToggle(player.id)}
                            />
                        </label>
                        </div>
                    </li>
                ))}
            </ul>
            <center><button onClick={handleOptimizeChem}>Optimise Chem</button></center>
        </div>
    );
};

SelectedPlayers.propTypes = {
    players: PropTypes.array.isRequired,
    onRemovePlayer: PropTypes.func.isRequired,
    onOptimizeChem: PropTypes.func.isRequired
};

export default SelectedPlayers;
